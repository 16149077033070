import axios from "axios";
import { useFormik } from "formik";
import  { useState } from "react";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
const useLoginHook = () => {
  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 5000
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post( `https://alrmoz.com/marsa_back/public/api/mobile/auth/login`, values );
      const token = response.data.data.token;
      window.localStorage.setItem("marsaToken", token);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 422) {
        notyf.error('Make sure you entered the phone number and password correctly');
      }
    } finally {
      setLoading(false);
    }   
  };

  const formik = useFormik({
    initialValues: {
      login: "",
      password: ""
    },
    onSubmit: handleSubmit,
  });

  return {loading, formik};
};

export default useLoginHook;