import React from "react";
import { Link } from "react-router-dom";
// import useSignUpHook from "./Hooks/useSignUpHook";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import useChenckPhoneHook from "./Hooks/useChenckPhoneHook";

const ForgitPassword = () => {
  const { loading, formik } = useChenckPhoneHook();
  const { t } = useTranslation();
  return (
    <div className="my-20 flex justify-center items-center">
      <form className="shadow-md w-full md:w-2/3 lg:w-1/3 py-4 px-4 md:px-10 mx-5 text-center flex flex-col" onSubmit={formik.handleSubmit}>
        <img src={require("../login.jpg")} width={200} className="mx-auto" alt="Login Image" />
        <h1 className="mt-10 text-3xl mb-4">
          {/* {t("signUp.pgaeOne.title")}  */}
          {t("checkEmaik.title")}
        </h1>
        <div className="flex items-center mb-8">
          <input
            className="border w-full h-9 py-2 px-5"
            type="text"
            placeholder={t("checkEmaik.email")}
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {loading ? (
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          ) : (
            t("checkEmaik.sent")
          )}
          
        </button>
      </form>
    </div>
  );
};

export default ForgitPassword;
