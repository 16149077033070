import React from "react";
import x from "./assets/Group.png";
import xx from "./assets/Group-1.png";
import xxx from "./assets/Group-2.png";
import xxxx from "./assets/Group 41654.png";

const PayNow = () => {
const payment = [
  {img: xxxx, title: "Credit Card"},
  {img: xx, title: "Mada"},
  {img: xxx, title: "apple pay"},
  {img: x, title: "STC"},
]

  return (
    <div className="mt-10 w-4/5 m-auto mb-20">
      <h1 className="text-3xl flex gap-5  mb-7 capitalize">{"Reservations"}</h1>
      <div className="flex w-4/5 justify-between items-start">
        <div className="flex flex-col">
          <div className="border rounded-xl p-5">
            <h3 className="text-3xl">Marsa Alam Tours for 7 Days</h3>
            <p className="text-slate-500 mt-2">Egypt . Marsa Alam</p>
          </div>
          <h3 className="mt-10 text-3xl">
            Choose the appropriate payment method for you
          </h3>
          <div className="boxesChoose grid gap-5 mt-3 mb-12" style={{gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))"}}>
            
            {payment.map((item) => (
              <label className="box border flex items-center justify-between p-4 rounded-lg cursor-pointer hover:shadow-lg transition-shadow duration-300">
              <input type="radio" name="paymentMethod" className="form-radio h-5 w-5 text-gray-600 transition duration-150 ease-in-out" />
              <img src={item.img} alt="Credit Card" className="w-10 h-10 ml-3" />
              <span className="ml-3 block text-sm font-medium text-gray-700">{item.title}</span>
            </label>
            ))}
          </div>
        <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold text-right text-gray-800 mb-6">
          ادخل بيانات البطاقة
        </h2>
        <form>
          <div className="mb-4">
            <label className="block text-right text-gray-600 mb-2" htmlFor="cardholder-name">
              إسم حامل البطاقة
            </label>
            <input
              type="text"
              id="cardholder-name"
              className="w-full px-3 py-2 text-right border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-right text-gray-600 mb-2" htmlFor="card-number">
              رقم البطاقة
            </label>
            <input
              type="text"
              id="card-number"
              className="w-full px-3 py-2 text-right border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
            />
          </div>
          <div className="flex justify-between mb-6">
            <div className="w-1/2 pr-2">
              <label className="block text-right text-gray-600 mb-2" htmlFor="expiry-date">
                تاريخ الانتهاء
              </label>
              <input
                type="text"
                id="expiry-date"
                className="w-full px-3 py-2 text-right border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
              />
            </div>
            <div className="w-1/2 pl-2">
              <label className="block text-right text-gray-600 mb-2" htmlFor="cvv">
                الرقم السري (CVV)
              </label>
              <input
                type="text"
                id="cvv"
                className="w-full px-3 py-2 text-right border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-pink-500 text-white py-3 rounded-md text-lg font-semibold hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-600"
          >
            الدفع
          </button>
        </form>
      </div>
          <div className="flex justify-center items-center w-full" style={{marginTop: "20px"}}>
          </div>
        </div>
        <div className="w-1/52t">
          <div className="border rounded-lg p-4 w-64 text-right">
            <h2 className="text-lg font-semibold mb-4">مراجعة الطلب</h2>
            <div className="mb-2">
              <span className="text-xl font-bold">15000$</span>
              <span className="block text-sm text-gray-500">
                5000$ × 3 أفراد
              </span>
            </div>
            <div className="mb-4">
              <span className="text-lg font-bold">400$</span>
              <span className="block text-sm text-gray-500">رسوم الخدمة</span>
            </div>
            <div className="border-t pt-2">
              <span className="text-xl font-bold">15400$</span>
              <span className="block text-sm text-gray-500">الاجمالي</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayNow;
