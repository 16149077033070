import React, { useEffect, useState } from "react";
import SectionTitleCentered from "../../../Components/SectionTitle/SectionTitleCentered";
import overlay from "./category_overlay.png";
import leftArrow from "./solar_arrow-left-outline.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { base_api } from "../../../utils/apis";
import AOS from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

export default function HomeCategories({ useTranslation }) {
  const { t, i18n } = useTranslation();
  const [allcards, setAllCards] = useState([]);

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.get(
          `${base_api}/${i18n.language}/api/mobile/home/get-some-trips`
        );
        setAllCards(response.data.data);
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };

    fetchTrips();
  }, [i18n.language]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      easing: "ease-in-out", // Easing function for animations
    });
  }, []);

  return (
    <section className={`pb-6 mx-auto w-[90%] ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
      {/* static frontEnd */}
      <SectionTitleCentered
        title={t("homeSections.homeCategories.title")}
        desc={t("homeSections.homeCategories.desc")}
      />
      <div className="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center my-10">
        {allcards.map((item, index) => (
          <Link to={`trips/${item.id}`} key={index}>
            <figure
              className="categories_card relative rounded-[30px] overflow-hidden"
              data-aos="fade-up"
              data-aos-delay={index * 100} // Stagger animations based on index
            >
              <img
                className="w-full"
                src={item.photo}
                alt=""
                style={{ height: "288.16px" }}
                data-aos="zoom-in"
                data-aos-delay={index * 150} // Stagger animations for images
              />
              <img
                className="absolute top-0 left-0 w-full h-full"
                src={overlay}
                alt=""
                data-aos="fade-in"
                data-aos-delay={index * 200} // Stagger animations for overlay
              />
              <div
                className="info absolute bottom-4 right-4 font-cairo"
                data-aos="fade-up"
                data-aos-delay={index * 250} // Stagger animations for info
              >
                <h4 className="text-white font-semibold my-2">{item.name}</h4>
                {i18n.language === "en" ? (
                  <Link
                    to={item.link}
                    className="flex items-center justify-end gap-3 mb-2 font-medium text-sm text-white"
                  >
                    <img src={leftArrow} alt="" width={13.12} height={10.14} />
                    {t("showTrips")}
                  </Link>
                ) : (
                  <Link
                    to={item.link}
                    className="flex items-center justify-start gap-3 mb-2 font-medium text-sm text-white"
                  >
                    {t("showTrips")}
                    <img src={leftArrow} alt="" width={13.12} height={10.14} />
                  </Link>
                )}
              </div>
            </figure>
          </Link>
        ))}
      </div>
      <div className="md:hidden my-10">
        <Swiper
          spaceBetween={5}
          slidesPerView={1.1}
          pagination={{ clickable: true, el: ".swiper-pagination" }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper h-100 mt-5"
        >
          {allcards.map((item, index) => (
            <SwiperSlide key={index}>
              <Link to={`trips/${item.id}`}>
                <figure
                  className="categories_card relative rounded-[30px] overflow-hidden"
                  data-aos="fade-up"
                  data-aos-delay={index * 100} // Stagger animations based on index
                >
                  <img
                    className="w-full"
                    src={item.photo}
                    alt=""
                    style={{ height: "288.16px" }}
                    data-aos="zoom-in"
                    data-aos-delay={index * 150} // Stagger animations for images
                  />
                  <img
                    className="absolute top-0 left-0 w-full h-full"
                    src={overlay}
                    alt=""
                    data-aos="fade-in"
                    data-aos-delay={index * 200} // Stagger animations for overlay
                  />
                  <div
                    className="info absolute bottom-4 right-4 font-cairo"
                    data-aos="fade-up"
                    data-aos-delay={index * 250} // Stagger animations for info
                  >
                    <h4 className="text-white font-semibold my-2">
                      {item.name}
                    </h4>
                    {i18n.language === "en" ? (
                      <Link
                        to={item.link}
                        className="flex items-center justify-end gap-3 mb-2 font-medium text-sm text-white"
                      >
                        <img
                          src={leftArrow}
                          alt=""
                          width={13.12}
                          height={10.14}
                        />
                        {t("showTrips")}
                      </Link>
                    ) : (
                      <Link
                        to={item.link}
                        className="flex items-center justify-start gap-3 mb-2 font-medium text-sm text-white"
                      >
                        {t("showTrips")}
                        <img
                          src={leftArrow}
                          alt=""
                          width={13.12}
                          height={10.14}
                        />
                      </Link>
                    )}
                  </div>
                </figure>
              </Link>
            </SwiperSlide>
          ))}
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </section>
  );
}
