import React from "react";
import { useTranslation } from "react-i18next";
import Hero from "../../Components/Hero/Hero";
import HomeCategories from "./HomeCategories/HomeCategories";
import HomeBestTrips from "./HomeBestTrips/HomeBestTrips";
import HomeLimitedOffer from "./HomeLimitedOffer/HomeLimitedOffer";
import HomeBestInnerTours from "./HomeBestInnerTours/HomeBestInnerTours";

export default function () {
  const {  t,  i18n: { language }, } = useTranslation();

  return (
    <main>
      <Hero useTranslation={useTranslation} />
      <HomeCategories useTranslation={useTranslation} />
      <HomeBestInnerTours useTranslation={useTranslation} />
      <HomeBestTrips useTranslation={useTranslation} />
      <HomeLimitedOffer useTranslation={useTranslation} />
      <div className="empty_space h-[60vh]"></div>
    </main>
  );
}