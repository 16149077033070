import React, { useEffect, useState } from "react";
import logo from "./assets/logo.png";
import mobileApp from "./assets/mobile_app.png";
import appStoreImage from "./assets/appstore-image.png";
import playStoreImage from "./assets/playstore-image.png";
import xIcon from "./assets/socialLinks/x.png";
import facebookIcon from "./assets/socialLinks/facebook.png";
import instagramIcon from "./assets/socialLinks/instagram.png";
import tiktokIcon from "./assets/socialLinks/tiktok.png";
import snapchatIcon from "./assets/socialLinks/snapchat.png";
import arrow from "./assets/arrow.png";
import locationIcon from "./assets/contactUs/location.png";
import phoneIcon from "./assets/contactUs/phone.png";
import mailIcon from "./assets/contactUs/mail.png";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../utils/baseLink";
import axios from "axios";
import { base_api } from "../../utils/apis";

export default function Footer({ isHomePage, useTranslation }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navgation = useNavigate();

  // start social
  const [social, setSocial] = useState([])
  const getAllSocial = async () => {
    try{
      const response = await axios.get(`https://alrmoz.com/marsa_back/public/ar/api/Dashboard/socials`);
      setSocial(response.data.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getAllSocial();
  }, [])

  const arrowClass = (isRtl) => `w-4 h-4 ${isRtl ? "transform rotate-180" : ""}`;

  return (
    <footer className="w-full relative bg-[#0F122C]">
      <div
        className={`container w-[90%] mx-auto flex flex-col gap-3 ${
          isHomePage && "pt-40"
        } relative`}
      >
        {/* Pre-Footer Section */}
        {isHomePage && (
          <div className="pre_footer absolute top-[-200px] left-0 right-0 mx-auto flex flex-col justify-center items-center">
            <div className="content bg-[#FD5674] rounded-[30px] w-full h-[300px] max-w-[1200px] flex items-center justify-center p-10">
              <div className="flex flex-col md:flex-row xs:gap-4 lg:gap-20 items-center justify-around">
                <div className="text-white text-center md:text-right font-cairo">
                  <p className="font-semibold mb-3">
                    {t("footer.preFooter.firstP")}{" "}
                  </p>
                  <h2 className="text-2xl font-bold">
                    {t("footer.preFooter.title")}{" "}
                  </h2>
                  <p className="mt-2 font-medium">
                    {t("footer.preFooter.secondP")}{" "}
                  </p>
                  <div className="flex gap-4 mt-4">
                    <img
                      src={appStoreImage}
                      alt="App Store"
                      className="w-[150px]"
                    />
                    <img
                      src={playStoreImage}
                      alt="Google Play"
                      className="w-[150px]"
                    />
                  </div>
                </div>
                <img
                  src={mobileApp}
                  alt="Marsa Tours Pre-Footer"
                  className="w-full md:w-1/2 object-contain pb-28"
                />
              </div>
            </div>
          </div>
        )}

        {/* Upper Section (Optional) */}
        <div className="upper_section h-[100px] flex justify-between items-center flex-col sm:flex-row">
          <figure className="logo">
            <img src={logo} alt="" />
          </figure>
          <div className="social_links">
            <ul className="flex items-center gap-6">
              {social.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.link}>
                      <img src={item.photo} width={30} alt="xx" />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Lower Section */}
        <div className="lower_section grid grid-cols-1 md:grid-cols-4 gap-8 text-white py-10 border-t border-b border-white border-opacity-10 font-cairo">
          <div>
            <h3 className="text-lg font-semibold mb-4">
              {t("footer.lowerSection.aboutUs")}
            </h3>
            <p className="font-medium text-[rgb(255,255,255,80%)] text-sm leading-7 w-4/5">
              {t("footer.lowerSection.aboutDesc")}
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">
              {t("footer.lowerSection.support")}
            </h3>
            <ul>
              <li className="font-medium flex items-center gap-2 mb-2">
                <img
                  src={arrow}
                  alt="Arrow"
                  className={arrowClass(language === "en")}
                />
                <li onClick={() => navgation(`/${base_url}`)} className="cursor-pointer"  >
                  {t("footer.lowerSection.returns")}
                </li>
              </li>
              <li className="font-medium flex items-center gap-2 mb-2">
                <img
                  src={arrow}
                  alt="Arrow"
                  className={arrowClass(language === "en")}
                />
                <li
                  onClick={() => navgation(`/${base_url}/portfolio`)}
                  className=" cursor-pointer"
                >
                  {t("footer.lowerSection.faq")}
                </li>
              </li>
              <li className="font-medium flex items-center gap-2 mb-2">
                <img
                  src={arrow}
                  alt="Arrow"
                  className={arrowClass(language === "en")}
                />
                <li
                  onClick={() => navgation(`/${base_url}/trips`)}
                  className=" cursor-pointer"
                >
                  {t("footer.lowerSection.contact")}
                </li>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">
              {t("footer.lowerSection.followUs")}
            </h3>
            <ul>
              <li className="flex items-center gap-2 font-medium mb-5">
                <img src={locationIcon} alt={"location"} className="w-6" />
                <p className="font-medium">'dummy'</p>
              </li>
              <li className="flex items-center gap-2 font-medium mb-5">
                <img src={mailIcon} alt={"location"} className="w-6" />
                <p className="font-medium">info@gmail.com</p>
              </li>
              <li className="flex items-center gap-2 font-medium mb-5">
                <img src={phoneIcon} alt={"location"} className="w-6" />
                <p className="font-medium">955 23123 123123</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="copy_rights flex justify-between text-white text-opacity-30 py-4 font-cairo font-medium">
          <p>
            © {new Date().getFullYear()} Rmoz. {t("footer.copyRights.copy")}
          </p>
          <div className="links flex gap-3">
            <Link>{t("footer.copyRights.privacy")}</Link>
            <p>|</p>
            <Link>{t("footer.copyRights.terms")}</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
