import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../utils/baseLink";
import { Notyf } from "notyf";

const useConfermDataHook = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'
    },
    duration: 5000
  });

  const handleSubmit = async (values) => {
    const phone  = window.localStorage.getItem("marsaPhone");
    try {
      setLoading(true);
      const response = await axios.post(
        `https://alrmoz.com/marsa_back/public/api/mobile/auth/register-user-page2/${phone}`,
        values
      );
      console.log(response.data.data);
      const email = response.data.data.email;
      window.localStorage.setItem("marsaEmail", email);
      navigate(`/${base_url}/authentication-signup/otp`);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        notyf.error('Make sure you entered the data correctly');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      country: "",
      password: "",
      password_confirmation: "",
    },
    onSubmit: handleSubmit,
  });

  return { formik, loading };
};

export default useConfermDataHook;
