import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const [policies, setPolicies] = useState([]);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const getAll = async () => {
    try {
      const response = await axios.get(
        `https://alrmoz.com/marsa_back/public/${i18n.language}/api/Dashboard/policys`
      );
      setPolicies(response.data.data);
    } catch (e) {
      console.error(e);
      setError("Failed to fetch privacy policies. Please try again later.");
    }
  };

  useEffect(() => {
    getAll();
  }, [i18n.language]);

  return (
    <div className="privacyPolicyContainer w-5/6 m-auto mt-20 mb-9">
      <h1 className="title text-3xl mb-4">{t("PrivacyPolicy")}</h1>
      {error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <ul className="content list-disc pl-5">
          {policies.map((policy, index) => (
            <li key={index} className="mb-2">
              <div>{policy.text}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PrivacyPolicy;
