import React, { useEffect, useState } from "react";
import PageBanner from "../../Components/PageBanner/PageBanner";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import stylesCategorys from "./showCaregotys.module.css";
import heart from "./assets/heart.png";
import mapIcon from "./assets/map_logo.png";
import arrow from "./assets/solar_round-arrow-left-up-bold.png";
import { base_api } from "../../utils/apis";
import { base_url } from "../../utils/baseLink";
import { isLogedIn } from "../../utils/token";

export default function ShowCategory() {
  const { trips } = useParams();
  const { t, i18n } = useTranslation();
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeCity, setActiveCity] = useState(""); // State for active city

  const fetchCities = async () => {
    try {
      const response = await axios.get(
        `${base_api}/${i18n.language}/api/mobile/trips/get-cities/${trips}`
      );
      setCities(response.data.data);
      fetchTrips();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTrips = async (city = "") => {
    try {
      const response = await axios.get(
        `${base_api}/${i18n.language}/api/mobile/trips/get-tours/${trips}`,
        { params: { city } } // Pass the city as a query parameter
      );
      setCards(response.data.data);
    } catch (error) {
      console.error("Error fetching trips:", error);
    }
  };

  const fetchResults = async () => {
    if (searchQuery.length < 1) {
      setCards([]); // Clear cards when searchQuery is empty
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `https://alrmoz.com/marsa_back/public/en/api/mobile/trips/search-toursByName/${trips}`,
        {
          params: { name: searchQuery },
        }
      );
      setCards(response.data.data); // Set cards to search results
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCities();
    if (searchQuery.length === 0) {
      fetchTrips(activeCity); // Fetch trips if there's no search query
    } else {
      fetchResults(); // Fetch search results if there's a query
    }
  }, [i18n.language, searchQuery, activeCity]);
  const navigateTo = useNavigate()
  return (
    <main>
      <PageBanner
        useTranslation={useTranslation}
        text={t("pageBanner.category")}
      />
      <div className="container w-[90%] mx-auto">
        <div className="flex my-5">
          <div
            className={`filter ${stylesCategorys.linksFillter} pb-3 flex-nowrap flex gap-4 w-[75%] overflow-x-auto`}
          >
            {cities.length > 0 ? (
              cities.map((city, index) => (
                <Link
                  key={index}
                  to={`/city/${city.toLowerCase()}`} // Assuming you want to link to a city-specific page
                  onClick={() => setActiveCity(city)}
                  className={`font-medium font-cairo border px-6 py-2 rounded-[60px] flex justify-center truncate ${
                    activeCity === city ? "bg-gray-200" : "" // Highlight active city
                  }`}
                  style={{ minWidth: "120px" }}
                >
                  {city}
                </Link>
              ))
            ) : (
              <p>No cities available</p> // Handle the case when there are no cities
            )}
          </div>
          <div className="flex-[0_0_25%] ml-4">
            <input
              className="w-full border h-10 px-2 outline-none"
              placeholder={t("search")}
              type="search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div
          className="cards grid mb-10 gap-10"
          style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
          }}
        >
          {cards.length === 0 && !loading && <p>{t("no results")}</p>}
          {loading && <p>{t("search.loading")}</p>}
          {cards.map((item) => (
            <div
              onClick={() => navigateTo(`/${base_url}/tripInfo/${item.id}`)}
              key={item.id}
              className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1 cursor-pointer"
              style={{ height: "500px" }}
            >
              <figure className="h-[60%] relative">
                <img
                  src={item.photo}
                  alt=""
                  style={{ height: "100%", width: "100%" }}
                />
                {isLogedIn && (
                  <button className="absolute top-4 left-4 p-3 bg-white shadow-md rounded-full">
                    <img src={heart} alt="" width={20} height={20} />
                  </button>
                )}
              </figure>
              <div className="card_info px-3">
                <p className="flex items-center gap-2 py-3">
                  <img src={mapIcon} alt="" />
                  <span className="text-[#777891]">
                    {item.country} {"."} {item.city}
                  </span>
                </p>
                <h3 className="font-bold text-xl truncate py-3">
                  {item.name} {"."} {"to"} {`${item.duration} Hours`}
                </h3>
              </div>
              <div className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]">
                <div className="right flex flex-col gap-2 w-[75%]">
                  <p className="text-[#777891] text-sm">
                    {t("homeSections.tripCardPrice")}
                  </p>
                  <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                    {item.price}{" "}
                    <span className="mx-2 font-semibold text-sm py-1">$</span>
                  </h2>
                </div>
                <div className="left w-[25%] h-full flex justify-end items-center">
                  <Link to={`/${base_url}/tripInfo/${item.id}`} className="">
                    <img
                      className="bg-[#FD5674] rounded-full p-2 "
                      src={arrow}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}
