import axios from "axios";
import React, { useState } from "react";
// import { api } from "../../../utils/apis";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../utils/baseLink";
import { Notyf } from "notyf";

const useSignUpHook = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'  
    },
    duration: 7000 
  });

  const validate = (values) => {
    const errors = {};
    if (!values.phone) {
      errors.phone = 'Required';
    } else if (values.phone.length < 10 || values.phone.length > 10) {
      errors.phone = 'Phone number must be 10 digits';
    } else if (values.phone.startsWith('0')) {
      errors.phone = 'Phone number cannot start with 0';
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`https://alrmoz.com/marsa_back/public/api/mobile/auth/register-user-page1`, values);
      console.log(response.data);
      // save data in login
      navigate(`/${base_url}/authentication-signup/confirm-information`);
      const phone = response.data.data.phone
      window.localStorage.setItem("marsaPhone", phone);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        notyf.error('Make sure you entered the phone number correctly');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: "1007481557",
      country_code: "20"
    },
    validate,
    onSubmit: handleSubmit,
  });

  return { handleSubmit, loading, formik };
};

export default useSignUpHook;