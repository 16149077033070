// import { useNavigate } from "react-router-dom";

export const logout = () => {
  // const home = useNavigate();
  window.localStorage.removeItem("marsaToken");
  window.location.reload();
};


export const isLogedIn = () => {
  return window.localStorage.getItem("marsaToken");
};