import axios from "axios";
import React, { useEffect, useState } from "react";
import { isLogedIn } from "../../utils/token";
import { base_api } from "../../utils/apis";
import { useTranslation } from "react-i18next";

const PastPayMent = () => {
  const { i18n } = useTranslation();
  const [payments, setPayments] = useState([]);
  const getData = async () => {
    try {
      const response = await axios.get(`${base_api}/${i18n.language}/api/mobile/orders/get-auth-payments`, {
        headers: {
          Authorization: `Bearer ${isLogedIn}`,
        },
      });
      console.log(response.data.data);
      setPayments(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="mt-10 w-2/4 m-auto mb-20">
      <h1 className="text-3xl mb-7 capitalize">{"past payments"}</h1>
      <div className="boxes border rounded-xl p-5">
        {payments.map((item) => (
          <div className="box border py-3 rounded-xl flex gap-5 items-center justify-between px-5">
            <div className="flex items-center justify-start">
              <img src={require("./assets/solar_arrow-to-top-left-outline.png")} width={70} alt="arrow"   />
              <div className="info">
                <p className="content mb-1">{item.tour_name}</p>
                <p className="time text-gray-600">{item.paid_date}</p>
              </div>
            </div>
            <p className="salary text-2xl" style={{ color: "#FD5674" }}>
              {item.total_price}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PastPayMent;
