import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enJSON from "./locale/en.json";
import arJSON from "./locale/ar.json";

i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enJSON },
      ar: { translation: arJSON },
    },
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "path", "navigator"],
      lookupLocalStorage: "i18nextLng",  // استرجاع اللغة من localStorage
      caches: ["localStorage"], // حفظ اللغة في localStorage
    },
    react: {
      useSuspense: false,
    },
  });

// Handle RTL languages and set the language direction
i18n.on("languageChanged", (lng) => {
  if (lng === "ar") {
    document.body.setAttribute("dir", "rtl");
  } else {
    document.body.setAttribute("dir", "ltr");
  }
});

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng).then(() => {
    // Set language direction after changing language
    if (lng === "ar") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.setAttribute("dir", "ltr");
    }
  });
};

export default i18n;