import React, { useEffect, useState } from "react";
import styles from "./hero.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import heroDevider from "./assets/devider.png";
import axios from "axios";
import { base_api } from "../../utils/apis";
import AOS from "aos";

export default function Hero({ useTranslation }) {
  const { t, i18n } = useTranslation();
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.get(
          `${base_api}/${i18n.language}/api/mobile/home/get-banners`
        );
        setBanners(response.data.data);
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };

    fetchTrips();
  }, [i18n.language]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations
      easing: "ease-in-out", // Easing function for animations
    });
  }, []);

  return (
    <section id="hero_section" className="mb-20 mt-1">
      <Swiper
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation={true}
        loop={true}
        dir={"rtl"}
        modules={[Pagination, Autoplay, Navigation]}
        className={"mySwiper"}
      >
        {banners.map((item, index) => (
          <SwiperSlide
            className={styles.swiper_slide}
            key={index}
          >
            <figure className={`${styles.slider_body}`} data-aos="fade-in">
              <img
                className={`${styles.hero_img}`}
                src={item.photo}
                alt="hero_slider"
              />
              <img className={styles.hero_devider} src={heroDevider} alt="" />
            </figure>
            <div
              className="slider_content absolute top-[28%] right-[10%] flex flex-col gap-6"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <p className="text-2xl font-medium text-[#fff] font-cairo">
                {" "}
                {t("heroContent.desc")}{" "}
              </p>
              <h1 className="text-4xl font-bold text-[#fff] font-cairo">
                {" "}
                {item.offer_name}{" "}
              </h1>
              <div
                className={`${styles.cost} bg-[#fff] flex overflow-hidden w-[270px]`}
                data-aos="zoom-in"
                data-aos-delay="500"
              >
                <h3 className="w-[30%] flex justify-center text-center bg-[#FD5674] font-cairo font-semibold py-3 px-2 text-[#fff]">
                  {t("heroContent.cost")}
                </h3>
                <h2 className="w-[70%] flex items-start justify-center px-2 text-4xl font-bold py-4 text-[#FD5674]">
                  {item.offer_price_value}
                  <span className="mx-2 font-semibold text-sm py-1">$</span>
                </h2>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
