import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import logo from "./assets/logo.png";
import avatarIcon from "./assets/user-avatar.svg";
import bell from "./assets/solar_bell-outline.png";
import { Link, useNavigate } from "react-router-dom";
import Login from "../Login/Login";
import { base_url } from "../../utils/baseLink";
import { isLogedIn, logout } from "../../utils/token";
import bellContent from "./assets/solar_bell-bing-outline.png";
import axios from "axios";
import "./navgationBar.css";

export default function NavBar(props) {
  const { useTranslation } = props;
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false); // إضافة الحالة هنا

  const [activeNotification, setActiveNotification] = useState(false);
  const notificationRef = useRef(null);

  const handleNotifications = () => {
    setActiveNotification(!activeNotification);
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setActiveNotification(false);
    }
  };

  useEffect(() => {
    if (activeNotification) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeNotification]);
  const [activeSide, setActiveSide] = useState(false);
  const handleToggle = () => {
    setActiveSide(!activeSide);
  };
  const handleClick = (link) => {
    setActiveSide(false);
    navigate(link);
  };
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = React.useState(language);

  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ar" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    window.location.reload()
  };

  const navigate = useNavigate();
  const pages = [
    { title: t("pages.home"), link: `/${base_url}`, key: "home" },
    { title: t("pages.trips"), link: `/${base_url}/trips`, key: "trips" },
    {
      title: t("pages.contactUs"),
      link: `/${base_url}/contactUs`,
      key: "contactUs",
    },
    {
      title: t("pages.PrivacyPolicy"),
      link: `/${base_url}/PrivacyPolicy`,
      key: "PrivacyPolicy",
    },
  ];
  const { i18n } = useTranslation();
  const token = isLogedIn();

  // notification
  const [notificationsData, setNotificationsData] = useState([]);
  const getAll = async () => {
    const token = isLogedIn();
    if (!token) {
      console.error("No token found. User is not logged in.");
      return;
    }
    try {
      const response = await axios.get(
        `https://alrmoz.com/marsa_back/public/en/api/mobile/notifications/get-all-notifications`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNotificationsData(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  const NotificationIcon = ({ type }) => {
    return (
      <div
        className={`w-6 h-6 rounded-full flex items-center justify-center ${
          type === "alert"
            ? "bg-red-500 text-white"
            : "bg-gray-300 text-gray-500"
        }`}
      >
        {type === "alert" ? (
          <img src={bell} alt={""} />
        ) : (
          <img src={bellContent} alt={""} />
        )}
      </div>
    );
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="navigation_bar relative bg-white z-50 top-0">
      <div
        className="navigate_bar_links fixed top-0 flex items-center justify-between px-20 w-screen py-5"
        style={{ backgroundColor: "white" }}
      >
        {/* brand */}
        <Link to={"/"} className="logo">
          <img width={100} src={logo} alt="Logo" />
        </Link>
        {/* links page */}
        <div className="navBarLinksLinks flex">
          {pages.map((page) => (
            <Button
              key={page.title}
              className="font-cairo"
              sx={{
                my: 2,
                color: "black",
                display: "block",
                fontWeight: "700",
                fontFamily: "Cairo",
              }}
              onClick={() => navigate(page.link)}
            >
              {page.title}
            </Button>
          ))}
        </div>
        <div className="flex items-center justify-center gap-5">
          {/* page controling */}
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Guest" src={avatarIcon} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {token ? (
              <>
                <MenuItem onClick={() => navigate(`/${base_url}/portfolio`)}>
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Cairo",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("authedUserSettings.profile")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate(`/${base_url}/recent_orders_Reservations`)
                  }
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Cairo",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("authedUserSettings.reservations")}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${base_url}/favorite`)}>
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Cairo",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("authedUserSettings.favourites")}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate(`/${base_url}/pastPayments`)}>
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Cairo",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("authedUserSettings.paymentProcesses")}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Cairo",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("authedUserSettings.logout")}
                  </Typography>
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={handleClickOpenDialog}>
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Cairo",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("notAuthedUserSettings.login")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate(`/${base_url}/authentication-signup`)}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      fontFamily: "Cairo",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                    }}
                  >
                    {t("notAuthedUserSettings.register")}
                  </Typography>
                </MenuItem>
              </>
            )}
            <MenuItem onClick={handleChangeLanguage}>
              <Typography
                textAlign="center"
                sx={{
                  fontFamily: "Cairo",
                  fontWeight: 500,
                  fontSize: "0.9rem",
                }}
              >
                {language === "en" ? "AR" : "EN"}
              </Typography>
            </MenuItem>
          </Menu>
          {/* notfcation */}
          <div className=" relative text-black notfcation">
            <div
              className="btn cursor-pointer w-full"
              onClick={handleNotifications}
            >
              <img
                src={require("./assets/solar_bell-bing-outline.png")}
                alt=""
                width={30}
              />
            </div>
            <div
              className={`notfcationContent bg-white rounded-lg shadow-2xl p-4 absolute top-16 ${i18n.language === "en" ? "right-0" : "left-0"}`}
              style={{
                width: "500px",
                height: "350px",
                overflowY: "scroll",
                display: activeNotification ? "block" : "none",
              }}
            >
              <div className="text-right text-gray-700 font-bold text-lg mb-4">
                الإشعارات
              </div>
              <div className="text-right text-gray-500 mb-4">
                اليوم . تنبيهات غير مقروءة
              </div>
              <div className="space-y-2">
                {notificationsData.map((notification) => (
                  <div
                    key={notification.id}
                    className="flex items-center justify-between p-4 border-b border-gray-200"
                  >
                    <NotificationIcon type={notification.type} />
                    <div className="text-right w-full mr-2">
                      <div
                        className={`font-bold ${
                          notification.type === "alert"
                            ? "text-blue-600"
                            : "text-gray-800"
                        }`}
                      >
                        {notification.title}
                      </div>
                      <div className="text-gray-400 text-sm">
                        {notification.body}
                      </div>
                      <div className="text-gray-400 text-sm">
                        {notification.created_at}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* side bare toggle btn */}
          <div className="border px-3 rounded-lg toggle_navgationBarControllingSideBar cursor-pointer" onClick={handleToggle}>
            <MenuIcon />
          </div>
        </div>
        {/* side bar links */}
        <div
          sx={{ display: { xs: "flex", md: "none" } }}
          className="text-black bg-white w-screen absolute top-20 m-auto"
          style={{
            left: activeSide ? "0" : "-110%",
            transition: "left 0.3s ease",
            height: "700px",
          }}
        >
          {pages.map((page) => (
            <Button
              key={page.title}
              className="font-cairo"
              style={{ fontSize: "18px" }}
              sx={{
                my: 2,
                color: "black",
                display: "block",
                fontWeight: "700",
                fontFamily: "Cairo",
              }}
              onClick={() => handleClick(page.link)}
            >
              {page.title}
            </Button>
          ))}
        </div>
        <Login open={openDialog} onClose={handleCloseDialog} />
      </div>
    </div>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};
