import React, { useEffect, useState } from "react";
import SectionTitle from "../../../Components/SectionTitle/SectionTitle";
import axios from "axios";
import { base_api } from "../../../utils/apis";
import { Link, useNavigate } from "react-router-dom";
import heart from "./assets/heart.png";
import mapIcon from "./assets/map_logo.png";
import arrow from "./assets/solar_round-arrow-left-up-bold.png";
import { base_url } from "../../../utils/baseLink";
import { isLogedIn } from "../../../utils/token";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

export default function HomeBestTrips({ useTranslation }) {
  const { t, i18n } = useTranslation();
  const [cards, setCards] = useState([]);
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.get(
          `${base_api}/${i18n.language}/api/mobile/home/get-favourite-tours`
        );
        setCards(response.data.data);
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };

    fetchTrips();
  }, [i18n.language]);
  const navigateTo = useNavigate();
  return (
    <section className="pb-6 mx-auto w-[90%]">
      <SectionTitle
        title={t("homeSections.homeBestTrips.title")}
        desc={t("homeSections.homeBestTrips.desc")}
        link=""
        useTranslation={useTranslation}
      />
      <div className="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center my-10">
        {cards.map((item, index) => (
          <div
            onClick={() => navigateTo(`/${base_url}/tripInfo/${item.id}`)}
            className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1 cursor-pointer"
            key={index}
            style={{ height: "500px" }}
          >
            <figure className="h-[60%] relative">
              <img
                src={item.photo}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
              {isLogedIn && (
                <button className="absolute top-4 left-4 p-3 bg-white shadow-md rounded-full">
                  <img src={heart} alt="" width={20} height={20} />
                </button>
              )}
            </figure>
            <div className="card_info px-3">
              <p className="flex items-center gap-2 py-3">
                <img src={mapIcon} alt="" />
                <span className="text-[#777891]">
                  {item.country} - {item.city} {"."} - {item.street}
                </span>
              </p>
              <h3 className="font-bold text-xl truncate py-3">{item.name}</h3>
            </div>
            <div className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]">
              <div className="right flex flex-col gap-2 w-[75%]">
                <p className="text-[#777891] text-sm">{"للفرد الواحد"}</p>
                <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                  {item.price}{" "}
                  <span className="mx-2 font-semibold text-sm py-1">$</span>
                </h2>
              </div>
              <div className="left w-[25%] h-full flex justify-end items-center">
                <Link to={`/${base_url}/tripInfo/${item.id}`} className="">
                  <img
                    className="bg-[#FD5674] rounded-full p-2 "
                    src={arrow}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="md:hidden my-10">
        <Swiper
          spaceBetween={5}
          slidesPerView={1.3} // Show one full card and part of the next card
          pagination={{ clickable: true, el: ".swiper-pagination" }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          // dir={language === "en" ? "ltr" : "rtl"}
          modules={[Pagination, Navigation]}
          className="mySwiper h-100 mt-5"
        >
          {cards.map((item, index) => (
            <SwiperSlide
              className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1"
              key={index}
              style={{ height: "500px" }}
            >
              <figure className="h-[60%] relative">
                <img
                  src={item.photo}
                  alt=""
                  style={{ height: "100%", width: "100%" }}
                />
                {isLogedIn && (
                  <button className="absolute top-4 left-4 p-3 bg-white shadow-md rounded-full">
                    <img src={heart} alt="" width={20} height={20} />
                  </button>
                )}
              </figure>
              <div className="card_info px-3">
                <p className="flex items-center gap-2 py-3">
                  <img src={mapIcon} alt="" />
                  <span className="text-[#777891]">
                    {item.country} - {item.city} {"."} - {item.street}
                  </span>
                </p>
                <h3 className="font-bold text-xl truncate py-3">{item.name}</h3>
              </div>
              <div className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]">
                <div className="right flex flex-col gap-2 w-[75%]">
                  <p className="text-[#777891] text-sm">{"للفرد الواحد"}</p>
                  <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                    {item.price}{" "}
                    <span className="mx-2 font-semibold text-sm py-1">$</span>
                  </h2>
                </div>
                <div className="left w-[25%] h-full flex justify-end items-center">
                  <Link to={`/${base_url}/tripInfo/${item.id}`} className="">
                    <img
                      className="bg-[#FD5674] rounded-full p-2 "
                      src={arrow}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </section>
  );
}
