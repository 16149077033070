import axios from "axios";
import { useFormik } from "formik";
import { Notyf } from "notyf";
import { useState } from "react";
import { base_url } from "../../../../utils/baseLink";
import { useNavigate } from "react-router-dom";

const useCheckCodePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const marsaEmail = window.localStorage.getItem("marsaEmail")
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
       const response = await axios.post(  `https://alrmoz.com/marsa_back/public/api/mobile/auth/verify-code-password/${marsaEmail}`, values );
      navigate(`/${base_url}/InterNewPassword`);
      console.log(response.data.data.code);
      const  ccode = response.data.data.code;
      window.localStorage.setItem("marsaCodePass", ccode);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 422) {
        Notyf.error("Make sure you entered the phone number correctly");
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: handleSubmit,
  });

  return {loading, formik};
};

export default useCheckCodePassword;
