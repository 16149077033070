import Select from "react-select";
import React from "react";
import countries from "./contrys.json";
import { CircularProgress, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import useConfermDataHook from "../Hooks/useConfermDataHook";
import { Translate } from "@mui/icons-material";
const ConfirmInfo = () => {
  const options = countries.map((country) => ({
    value: country.name,
    label: country.name,
  }));
  const { t } = useTranslation();
  const { formik, loading } = useConfermDataHook();

  return (
    <div className="my-20 flex justify-center items-center">
      <form className="shadow-md w-full md:w-2/3 lg:w-1/3 py-4 px-4 md:px-10 mx-5 text-center flex flex-col" onSubmit={formik.handleSubmit}>
        <h1 className="text-2xl mb-2" style={{ fontWeight: "bold" }}>
          {t("signUp.pageTwo.title")}
        </h1>
        <p className="mb-10 text-slate-400">
        {t("signUp.pageTwo.Description")}
        </p>
        <div className='edit_avatar relative flex'>
          <img className="mb-10 flex justify-center items-center relative left-1/2" style={{transform: "TranslateX(-50%)"}} src={require("../assets/avatar.png")} alt="" />
          <img src={require('../assets/edit.png')} className="absolute bg-red-600 p-2 rounded-full left-1/2 bottom-7 " alt="edit icon" />
        </div>
        <div className="form w-full">
          <div className="flex flex-col text-left mb-4">
            <label htmlFor="name">{t("signUp.pageTwo.labels.name")}</label>
            <input className="border w-full h-10 px-3 rounded-lg outline-none" type="text" placeholder="inter your name" id="name" name={"name"} value={formik.values.name} onChange={formik.handleChange}/>
          </div>
          <div className="flex flex-col w-full text-left mb-4">
            <label htmlFor="email">{t("signUp.pageTwo.labels.email")}</label>
            <input className="border w-full h-10 px-3 rounded-lg outline-none" type="email" placeholder="inter your email" id="email" name={"email"} value={formik.values.email} onChange={formik.handleChange} />
          </div>
          <div className="flex flex-col text-left w-full mb-4">
            <label htmlFor="country">{t("signUp.pageTwo.labels.contry")}</label>
            <Select options={options} placeholder="اختر الدولة..." onChange={(option) => formik.setFieldValue('country', option.value)} />
          </div>
          <div className="flex flex-col text-left w-full mb-4">
            <label htmlFor="password">{t("signUp.pageTwo.labels.password")}</label>
            <input
              className="border w-full h-10 px-3 rounded-lg outline-none"
              type="password"
              placeholder="inter your password"
              id="password"
              name={"password"}
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col text-left w-full mb-4">
            <label htmlFor="confirm_password">{t("signUp.pageTwo.labels.confermPass")}</label>
            <input
              className="border w-full h-10 px-3 rounded-lg outline-none"
              type="password"
              placeholder="inter your re type password"
              id="confirm_password"
              name={"password_confirmation"}
              value={formik.values.password_confirmation}
              onChange={formik.handleChange}
            />
          </div>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded">
          {loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : t("signUp.pageTwo.btnSubmet")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfirmInfo;
