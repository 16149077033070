import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { base_api } from "../../utils/apis";
import { isLogedIn } from "../../utils/token";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { base_url } from "../../utils/baseLink";

const Reservations = () => {
  const { i18n } = useTranslation();
  const [recent_orders, setRecent_orders] = useState([]);
  const navigate = useNavigate();

  const getall = async () => {
    const token = isLogedIn();
    if (!token) {
      console.error("No token found. User is not logged in.");
      return;
    }
    try {
      const response = await axios.get(
        `${base_api}/${i18n.language}/api/mobile/orders/get-orders`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRecent_orders(response.data.data.recent_orders);
      console.log(response.data.data.recent_orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getall();
  }, [i18n.language]);

  return (
    <div className="mt-10 w-full sm:w-5/6 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 m-auto mb-20">
      <h1 className="text-3xl mb-7 capitalize">{t("Reservations.title")}</h1>
      <div className="boxes border rounded-xl p-5">
        <div className="btnsControllers flex w-full gap-5">
          <button
            className="w-2/4 rounded-xl h-10 text-2xl"
            style={{ backgroundColor: "#FD5674" }}
            onClick={() => navigate(`/${base_url}/recent_orders_Reservations`)}
          >
            {t("Reservations.btns.Curr_tit")}
          </button>
          <button
            className="w-2/4 rounded-xl h-10 text-2xl border border-red-400"
            onClick={() => navigate(`/${base_url}/old_orders`)}
          >
            {t("Reservations.btns.Prev_tit")}
          </button>
        </div>
        {recent_orders.map((item, index) => (
          <div
            key={index}
            className="box border mt-10 py-3 rounded-xl gap-5 items-center px-5"
          >
            <div className="content flex gap-5 items-center">
              <img src={item.photo} width={100} alt="" />
              <div>
                <p className="location text-slate-400">
                  {item.country} {"."} {item.city} {"."} {item.street}
                </p>
                <div className="relative">
                  <h6 className="title mt-2">
                    {item.tour_name.length > 10
                      ? `${item.tour_name.substring(0, 10)}...`
                      : item.tour_name}
                  </h6>
                  {item.tour_name.length > 10 && (
                    <span className="absolute top-0 left-0 mt-2 w-max bg-black text-white text-sm rounded p-2 opacity-0 transition-opacity duration-300 hover:opacity-100">
                      {item.tour_name}
                    </span>
                  )}
                </div>

                <p className="text-xl text-gray-400">{item.total_price}$</p>
              </div>
            </div>
            <div
              className="moreInforamtion flex items-center justify-between pt-2 mt-5"
              style={{ borderTop: "1px solid #777" }}
            >
              <div className="content flex gap-10">
                <div className="time flex flex-col">
                  <span className="text-slate-500">Start Date</span>
                  <span className="text-2xl">{item.start_tour_date}</span>
                </div>
                <div className="PersonCount flex flex-col">
                  <span className="text-slate-500">Number of People</span>
                  <span className="text-xl">{item.vistiors_number}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reservations;
