import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import homeIcon from "./assets/home_icon.png";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export default function BreadCrumbs({ category, categoryId }) {
  const { t } = useTranslation();

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/marsa_tours">
      {t("breadcrumbs.home")}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/marsa_tours/category/${categoryId}`}
    >
      {category}
    </Link>,
    <Typography key="3" color="text.primary">
      {t("breadcrumbs.trip")}
    </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <div className="flex gap-4 items-center">
        <img src={homeIcon} alt="home_icon" className="w-[16px] h-[16px]" />
        <Breadcrumbs separator="|" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>
    </Stack>
  );
}
