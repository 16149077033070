import React from 'react'
import { useTranslation } from 'react-i18next';

const EditPortfolio = () => {
  const { t } = useTranslation();
  return (
    <div className="my-10 w-1/3 m-auto">
      <form className="shadow-lg pt-5 pb-2 px-4 flex flex-col justify-center items-center" 
      // onSubmit={formik.handleSubmit}
      >
        <h1 className="text-2xl mb-2" style={{ fontWeight: "bold" }}>
          {/* {t("signUp.pageTwo.title")} */}
          {"edit profile"}
        </h1>
        <p className="mb-10 text-slate-400">
        {/* {t("signUp.pageTwo.Description")} */}
        </p>
        <div className='edit_avatar relative flex'>
          <img src={require('./assets/edit.png')} className="absolute bg-red-600 p-2 rounded-full" style={{right: '0'}} alt="edit icon" />
          <img className="mb-10" src={require("./assets/avatar.png")} alt="" />
        </div>
        <div className="form w-full">
          <div className="flex flex-col w-full mb-4">
            <label htmlFor="name ">{t("signUp.pageTwo.labels.name")}</label>
            <input
              className="border w-full h-10 px-3 rounded-lg outline-none"
              type="text"
              placeholder="inter your name"
              id="name"
              name={"name"}
              // value={formik.values.name}
              // onChange={formik.handleChange}
            />
          </div>
          <div className="flex flex-col w-full mb-4">
            <label htmlFor="email">{t("signUp.pageTwo.labels.email")}</label>
            <input
              className="border w-full h-10 px-3 rounded-lg outline-none"
              type="email"
              placeholder="inter your email"
              id="email"
              name={"email"}
              // value={formik.values.email}
              // onChange={formik.handleChange}
            />
          </div>
          {/* <div className="flex flex-col w-full mb-4">
            <label htmlFor="password">{t("signUp.pageTwo.labels.password")}</label>
            <input
              className="border w-full h-10 px-3 rounded-lg outline-none"
              type="password"
              placeholder="inter your password"
              id="password"
              name={"password"}
              // value={formik.values.password}
              // onChange={formik.handleChange}
            />
          </div> */}
          {/* <div className="flex flex-col w-full mb-4">
            <label htmlFor="confirm_password">{t("signUp.pageTwo.labels.confermPass")}</label>
            <input
              className="border w-full h-10 px-3 rounded-lg outline-none"
              type="password"
              placeholder="inter your re type password"
              id="confirm_password"
              name={"password_confirmation"}
              // value={formik.values.password_confirmation}
              // onChange={formik.handleChange}
            />
          </div> */}
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded">
          {/* {loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : } */}
          {/* {t("signUp.pageTwo.btnSubmet")} */}
            <button>save changed</button>
            <button>remove</button>
          </button>
        </div>
      </form>
    </div>
  )
}

export default EditPortfolio;