import axios from "axios";
import { useFormik } from "formik";
// import Notyf from "notyf"; // Ensure this is imported correctly
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../../utils/baseLink";
import { Notyf } from "notyf";
import Swal from "sweetalert2";

const useInterNewPasswordHook = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const marsaEmail = window.localStorage.getItem("marsaEmail");
//   const notyf = new Notyf(); // Instantiate Notyf

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await axios.post(
        `https://alrmoz.com/marsa_back/public/api/mobile/auth/reset-password/${marsaEmail}`,
        values
      );
      
      navigate(`/${base_url}`);
      Notyf.success("Password reset successfully!");
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 422) {
        Swal.fire({
            title: "Error",
            text: "Make sure you entered the code correctly",
            icon: "error"
        })
      } else {
        Notyf.error("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const getIngCode = window.localStorage.getItem("marsaCodePass");
  const formik = useFormik({
    initialValues: {
      code: getIngCode || "", // Ensure the code is not null
      Password: "",
      password_confirmation: "",
    },
    onSubmit: handleSubmit,
  });

  return { loading, formik };
};

export default useInterNewPasswordHook;
