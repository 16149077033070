import React from "react";

export default function SectionTitleCentered({ title, desc }) {
  return (
    <div className="section_title_centered w-full flex flex-col gap-3 items-center font-cairo my-5">
      <h2 className="font-bold text-2xl">{title}</h2>
      <p className="font-semibold text-[#777891] text-lg">{desc}</p>
    </div>
  );
}
