import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { isLogedIn } from '../../utils/token';
import { Link } from 'react-router-dom';
import { base_url } from '../../utils/baseLink';
import avatar from './avatar.png'; // Changed to import for better practice

const Portfolio = () => {
  const [user, setUser] = useState(null); // Initialize with null to handle loading state

  const userinfo = async () => {
    try {
      const response = await axios.get(
        'https://alrmoz.com/marsa_back/public/api/mobile/profile/get-auth',
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('marsaToken')}`,
          },
        }
      );

      // Log the entire response for debugging
      console.log('Full Response:', response);

      // Check if the data and data.data exist before accessing
      if (response && response.data && response.data.data) {
        setUser(response.data.data);
        console.log('User Data:', response.data.data);
      } else {
        console.error('No user data found in response');
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    userinfo();
  }, []);

  if (user === null) {
    return <div className='mt-20'>Loading...</div>; // Display a loading state while fetching data
  }

  return (
    <div className="w-11/12 mt-32 m-auto">
      <h2 className="text-2xl sm:text-4xl">User Information</h2>
      <div
        className="userInformation flex flex-col sm:flex-row gap-5 items-center pt-5 px-5 sm:px-10 py-12 my-10"
        style={{
          border: '3px solid rgba(253, 86, 116, 0.3)',
          borderRadius: '20px',
        }}
      >
        <img
          src={avatar}
          className="border border-white rounded-full p-1 w-24 sm:w-32"
          alt="User Avatar"
        />
        <div>
          <h3 className="text-black mb-4 text-2xl sm:text-4xl">
            {'Your name'}: {user.name || 'N/A'}
          </h3>
          <h3 className="text-black text-xl sm:text-2xl">
            {'Your email'}: {user.email || 'N/A'}
          </h3>
          <h3 className="text-black text-xl sm:text-2xl mb-5">
            {'Phone number'}: {user.phone || 'N/A'}
          </h3>
          <div className="flex flex-col sm:flex-row gap-5">
            <Link
              to={`/${base_url}/pastPayments`}
              className="bg-black px-5 text-white p-2 rounded-lg text-center"
            >
              Get All Payments
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
