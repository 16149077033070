import axios from "axios";
import React, { useEffect, useState } from "react";

const Contact = () => {
  const cardStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gap: "30px",
  };

  const itemStyle = {
    width: "100%",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "#f7e8f9",
    textAlign: "center",
    fontFamily: "'Arial', sans-serif",
  };

  const iconStyle = {
    fontSize: "24px",
    color: "#bb31c5",
    width: "100px",
    display: "inline-block",
  };

  const titleStyle = {
    color: "#bb31c5",
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: "bold",
  };

  const infoStyle = {
    fontSize: "18px",
    color: "#333",
  };

  const [getD, setGetD] = useState([]);

  const get = async () => {
    try {
      const response = await axios.get(
        `https://alrmoz.com/marsa_back/public/en/api/Dashboard/contactus`
      );
      console.log(response.data);
      setGetD(response.data.data);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div className="w-4/5 m-auto mt-20" style={{ height: "100vh" }}>
      <h4 className="title text-4xl mb-10">contact us</h4>
      <div style={cardStyle}>
        {getD.map((item) => (
          <div style={itemStyle}>
            <img src={item.photo} style={iconStyle} alt="" />
            <div style={titleStyle}>{item.name}</div>
            <div style={infoStyle}>{item.email}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;
