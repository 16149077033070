import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../../utils/baseLink";
import { Notyf } from "notyf";

const useOTPHook = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [resending, setResending] = useState(false); // New state for resend action

  const notyf = new Notyf({
    position: {
      x: 'right',
      y: 'top'  
    },
    duration: 7000 
  });

  const handleSubmit = async (values) => {
    const marsaEmail = window.localStorage.getItem("marsaEmail");
    try {
      setLoading(true);
      const response = await axios.post(
        `https://alrmoz.com/marsa_back/public/api/mobile/auth/verify-phone-code/${marsaEmail}`,
        values
      );
      const token = response.data.data.token;
      window.localStorage.setItem("marsaToken", token)
      window.location.reload();
      navigate(`/${base_url}`);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        notyf.error('Make sure you entered the phone number correctly');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // https://alrmoz.com/marsa_back/public/api/mobile/auth/resend-code/${marsaEmail}
  const handleResendCode = async () => {
    const marsaEmail = window.localStorage.getItem("marsaEmail");
    try {
      setResending(true);
      await axios.post(
        `https://alrmoz.com/marsa_back/public/api/mobile/auth/resend-code/${marsaEmail}`
      );
      notyf.success('The code has been resent successfully');
    } catch (error) {
      notyf.error('Failed to resend the code. Please try again.');
      console.error(error);
    } finally {
      setResending(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    onSubmit: handleSubmit,
  });

   return { formik, loading, resending, handleResendCode };
};

export default useOTPHook;
