import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import shareIcon from "./assets/solar_share-broken.png";
import mapIcon from "../../Components/TripCard/assets/map_logo.png";
import roomIcon from "./assets/Services/room.svg";
import airIcon from "./assets/Services/air_conditioner.svg";
import wifiIcon from "./assets/Services/wifi.svg";
import foodIcon from "./assets/Services/free_breakfast.svg";
import leftArrow from "./assets/left_arrow.svg";
import { FormControl } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { base_api } from "../../utils/apis";
import { isLogedIn } from "../../utils/token";
import Swal from "sweetalert2";

export default function ShowTrip() {
  const [selectedImage, setSelectedImage] = useState(0);
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [information, setInformation] = useState([]);
  const [images, setImages] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [serveses, setServeses] = useState([]);

  const getInfo = async () => {
    try {
      const response = await axios.get(
        `${base_api}/${i18n.language}/api/mobile/tours/get-tour/${id}`
      );
      setInformation(response.data.data);
      setImages(response.data.data.photos);
      setAdditionalData(response.data.data.additional_data);
      setServeses(response.data.data.services);
    } catch (error) {
      console.log(error);
    }
  };

  const [startTourDate, setStartTourDate] = useState("");
  const [visitorsNumber, setVisitorsNumber] = useState(0);
  const [childrenNumber, setChildrenNumber] = useState(0);

  // Handle form submission
  const handleSubmit = async () => {
    const token = isLogedIn();
    if (!token) {
      Swal.fire({
        title: "Not Logged In",
        text: "Please log in to proceed with your order.",
        icon: "warning",
      });
      return;
    }
    try {
      const res = await axios.post(
        `${base_api}/${i18n.language}/api/mobile/orders/store`,
        {
          start_tour_date: startTourDate,
          vistiors_number: visitorsNumber,
          children_number: childrenNumber,
          tour_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Order Response:", res.data);

      const orderID = res.data.data.id;
      console.log("Order ID:", orderID);
      window.localStorage.setItem("orderID_to_payment", orderID);
      handlepayment();
    } catch (error) {
      console.error("Error submitting order:", error);
      Swal.fire({
        title: "Error",
        text: "Please check your order data and try again 😢🤦‍♀️",
        icon: "error",
      });
    }
  };

  // Request to payment API
  const handlepayment = async () => {
    const token = isLogedIn();
    if (!token) {
      Swal.fire({
        title: "Not Logged In",
        text: "Please log in to proceed with your order.",
        icon: "warning",
      });
      return;
    }
    const orderIDLocal = window.localStorage.getItem("orderID_to_payment");
    console.log("Stored Order ID:", orderIDLocal);
    try {
      const paymentRes = await axios.post(
        "https://alrmoz.com/marsa_back/public/en/api/mobile/payments/send-payment",
        {
          orderId: orderIDLocal,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(paymentRes);

      // Assuming paymentRes.data contains the payment URL
      if (paymentRes.data.data.url) {
        window.open(paymentRes.data.data.url, "_blank");
      } else {
        Swal.fire({
          title: "Payment Error",
          text: "There was an issue with initiating the payment 😢",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, [i18n.language]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        });
        console.log("Successfully shared");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  return (
    <main>
      <div className="container w-[90%] mx-auto pb-36 mt-10">
        <div className="page_header flex flex-col sm:flex-row justify-between py-5 font-cairo font-medium">
          <BreadCrumbs category={information.trip_name} categoryId={1} />
          <div className="share mt-4 sm:mt-0">
            <button className="flex gap-2" onClick={handleShare}>
              {information.trip_name}
              <img src={shareIcon} alt="" width={24} height={24} />
            </button>
          </div>
        </div>
        <div className="gallery flex flex-col md:flex-row gap-3 pb-10">
          <div className="current_img w-full md:w-[80%] rounded-[10px] overflow-hidden h-[320px] md:h-[560px]">
            <figure className="h-full w-full relative">
              <img
                src={images[selectedImage]}
                alt=""
                className="w-full h-full object-cover"
              />
            </figure>
          </div>
          <div className="others w-full md:w-[19%] h-[200px] flex md:flex-col gap-2 md:gap-4 justify-between">
            {images.map(
              (img, index) =>
                index <= 2 && (
                  <figure
                    key={index}
                    className="h-[100%] md:h-[40%] rounded-[10px] overflow-hidden"
                    onClick={() => setSelectedImage(index)}
                  >
                    <img src={img} alt="" className="w-full" />
                  </figure>
                )
            )}
          </div>
        </div>

        <div className="info flex flex-col md:flex-row gap-4">
          <div className="content w-full md:w-[70%] flex flex-col gap-4">
            <div className="trip_name border-[0.7px] flex flex-col sm:flex-row justify-between items-center border-[rgb(186,192,202,20%)] rounded-[10px] px-6">
              <div className="right text-center sm:text-left">
                <h3 className="font-bold text-xl py-3 font-cairo">
                  {information.name}
                </h3>
                <p className="flex items-center justify-center sm:justify-start gap-2 py-3">
                  <img src={mapIcon} alt="" />
                  <span className="text-[#777891]">
                    {information.country} {"."} {information.city} {"."}{" "}
                    {information.street}
                  </span>
                </p>
              </div>
              <h2 className="left flex flex-col items-center gap-2 font-cairo mt-4 sm:mt-0">
                <span className="text-[#777891] text-lg font-medium">
                  {t("detailsPage.duration")}{" "}
                </span>
                <span className="text-[#FD5674] text-2xl font-bold">{`${
                  information.duration
                } ${t("detailsPage.durationByDays")}`}</span>
              </h2>
            </div>
            <div className="desc border-[0.7px] border-[rgb(186,192,202,20%)] rounded-[10px] py-4 px-6">
              <div className="descContent flex flex-col justify-around gap-6 pb-5">
                <h2 className=" font-cairo font-semibold text-2xl">
                  {t("detailsPage.desc")}
                </h2>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <p className="font-medium font-cairo">
                  {information.description}
                </p>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
              </div>
              <div className="servicesSection flex flex-col justify-around gap-6 pb-5">
                <div className="title">
                  <h2 className=" font-cairo font-semibold text-2xl">
                    {t("detailsPage.services")}
                  </h2>
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <div className="services flex flex-wrap md:flex-nowrap gap-10 md:gap-20">
                  {serveses.map((item) => (
                    <div className="service flex items-center gap-4">
                      <img src={leftArrow} alt="" />
                      <h4 className="font-cairo font-medium ">{item}</h4>
                    </div>
                  ))}
                </div>

                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
              </div>

              <div className="rulesContent flex flex-col justify-around gap-6 pb-5">
                <div className="title">
                  <h2 className=" font-cairo font-semibold text-2xl">
                    {t("detailsPage.rules")}
                  </h2>
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <div className="rules">
                  <ul className="flex flex-col gap-4">
                    {additionalData === 0 ? (
                      <li className="flex items-center gap-4 font-cairo font-medium text-[#777891]"></li>
                    ) : Array.isArray(additionalData) &&
                      additionalData.length > 0 ? (
                      additionalData.map((item, index) => (
                        <li
                          key={index}
                          className="flex items-center gap-4 font-cairo font-medium text-[#777891]"
                        >
                          <img src={leftArrow} alt="" />
                          {item}
                        </li>
                      ))
                    ) : (
                      <li className="flex items-center gap-4 font-cairo font-medium text-[#777891]">
                        <img src={leftArrow} alt="" />
                        {additionalData}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {/* location
            <div className="location">
              <div className="title">
                <h2 className=" font-cairo font-semibold text-2xl">
                  {t("detailsPage.location")}
                </h2>
              </div>
            </div> */}
          </div>

          {/* pay card */}
          <div className="cost flex flex-col gap-6 w-full md:w-[30%] border-[0.7px] border-[rgb(186,192,202,20%)] rounded-[10px] p-4">
            <h2 className="left flex  items-center justify-between font-cairo">
              <span className="text-[#777891] text-lg font-medium">
                {t("detailsPage.cost")}
              </span>
              <span className="text-[#FD5674] text-3xl font-bold">
                {information.price}
                <span className="text-xs mx-2">$</span>
              </span>
            </h2>
            {/*  start form get this rehla  */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {/* Date Field */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label shrink htmlFor="date">
                  {t("detailsPage.costForm.date")}
                </label>
                <input
                  id="date-input"
                  type="date"
                  onChange={(e) => setStartTourDate(e.target.value)}
                  className="block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </FormControl>

              {/* Visitors Field */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>{t("detailsPage.costForm.visitors")}</label>
                <input
                  type="number"
                  min={1}
                  placeholder="Visitors"
                  onChange={(e) =>
                    setVisitorsNumber(
                      e.target.value ? Number(e.target.value) : 0
                    )
                  }
                  className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                />
              </FormControl>

              {/* Children Field */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>{t("detailsPage.costForm.childs")}</label>
                <input
                  type="number"
                  min={1}
                  placeholder={t("detailsPage.costForm.childsPlaceholder")}
                  onChange={(e) =>
                    setChildrenNumber(
                      e.target.value ? Number(e.target.value) : 0
                    )
                  }
                  className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                />
              </FormControl>
              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-[#FD5674] text-white py-2 px-4 rounded-lg font-cairo font-medium hover:bg-[#e44d6a] focus:outline-none focus:ring-2 focus:ring-[#e44d6a] focus:ring-opacity-50"
              >
                {t("detailsPage.costForm.getThis")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
