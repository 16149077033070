import React from "react";
import { Link } from "react-router-dom";

export default function SectionTitle({ title, desc, link, useTranslation }) {
  const { t } = useTranslation();
  return (
    <div className="section_title w-full flex justify-between gap-3 items-center font-cairo my-5">
      <div className="right flex flex-col gap-3 ">
        <h2 className="font-bold text-2xl">{title}</h2>
        <p className="font-semibold text-[#777891] text-lg">{desc}</p>
      </div>
      <div className="left">
        {link && (
          <Link className="text-[#316BDB] font-bold" to={link}>
            {t("homeSections.sectionTitleMore")}
          </Link>
        )}
      </div>
    </div>
  );
}
