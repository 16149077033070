import { useTranslation } from "react-i18next";
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import ShowCategory from "./Pages/ShowCategory/ShowCategory";
import Favorites from "./Pages/Favorites/Favorites";
import ShowTrip from "./Pages/ShowTrip/ShowTrip";
import { base_url } from "./utils/baseLink";
import AllCategorys from "./Pages/AllCategorys/AllCategorys";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Contact from "./Pages/Contact/Contact";
import SignIn from "./Pages/SginUp/SignUp";
import ConfirmInfo from "./Pages/SginUp/Confirm-information/ConfirmInfo";
import EditPortfolio from "./Pages/editPortfolio/EditPortfolio";
import OTP from "./Pages/SginUp/otp/OTP";
import { isLogedIn } from "./utils/token";
import ForgitPassword from "./Components/Login/forgitPassword/ForgitPassword";
import PastPayMent from "./Pages/PastPayment/PastPayMent";
import Reservations from "./Pages/Reservations/Reservations";
// import Reservations_Info from "./Pages/Reservations_Info/Reservations_Info";
import PayNow from "./Pages/PayNow/PayNow";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Old_Reservations from "./Pages/Old_Reservations/Old_Reservations";
import FormCheckCodeNewPassword from "./Components/Login/forgitPassword/FormCheckCodeNewPassword";
import InterNewPassword from "./Components/Login/forgitPassword/InterNewPassword";

function App() {
  const location = useLocation();
  const isHomePage = location.pathname === "/marsa_tours";

  return (
    <>
      <NavBar useTranslation={useTranslation} />
      <Routes>
        <Route path={`/`} element={<Navigate to={`/${base_url}/`} />} />
        {/* start authentication pages */}
        {isLogedIn ? null : (
          <>
            <Route path={`/${base_url}/authentication-signup`} element={<SignIn />} />
            <Route path={`/${base_url}/authentication-signup/confirm-information`} element={<ConfirmInfo />} />
            <Route path={`/${base_url}/authentication-signup/otp`} element={<OTP />} />
            <Route path={`/${base_url}/forgitPassword`} element={<ForgitPassword />} />
            <Route path={`/${base_url}/checkEmailAccount`} element={<FormCheckCodeNewPassword />} />
            <Route path={`/${base_url}/InterNewPassword`} element={<InterNewPassword />} />
          </>
        )}
        <Route path={`/${base_url}/auth-edit-profile`} element={<EditPortfolio />} />
        <Route path={`/${base_url}/portfolio`} element={<Portfolio />} />
        {/* forgit password */}

        {/* !!!! end authentication pages !!!! */}
        <Route path={`/${base_url}`} element={<Home />} />
        {/* all categorys */}
        <Route path={`/${base_url}/trips`} element={<AllCategorys />} />
        <Route path={`/${base_url}/trips/:trips`} element={<ShowCategory />} />
        {/* trips */}
        <Route path={`/${base_url}/tripInfo/:id`} element={<ShowTrip />} />
        <Route path={`/${base_url}/favorite`} element={<Favorites />} />
        <Route path={`/${base_url}/PrivacyPolicy`} element={<PrivacyPolicy />} />
        <Route path={`/${base_url}/contactUs`} element={<Contact />} />
        <Route path={`/${base_url}/pastPayments`} element={<PastPayMent />} />
        <Route path={`/${base_url}/recent_orders_Reservations`} element={<Reservations />} />
        <Route path={`/${base_url}/old_orders`} element={<Old_Reservations />} />
        {/* <Route path={`/${base_url}/Reservations_Info`} element={<Reservations_Info />} /> */}
        <Route path={`/${base_url}/pay_now`} element={<PayNow />} />
        {/* <Route path={`/${base_url}/ed`} element={<PayNow />} /> */}
        {/* error handling */}
        <Route path="*" element={<Navigate to={`/${base_url}`} />} />
      </Routes>
      {/* Pass the isHomePage boolean to the Footer */}
      <Footer useTranslation={useTranslation} isHomePage={isHomePage} />
    </>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
