import axios from "axios";
import { useFormik } from "formik";
import { Notyf } from "notyf";
import React, { useState } from "react";
import { base_url } from "../../../../utils/baseLink";
import { useNavigate } from "react-router-dom";

const useChenckPhoneHook = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://alrmoz.com/marsa_back/public/api/mobile/auth/forgot-password`,
        values
      );
      const email = response.data.data.email;
      window.localStorage.setItem("marsaEmail", email);
      navigate(`/${base_url}/checkEmailAccount`);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Notyf.error("Make sure you entered the phone number correctly");
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
        email: ""
    },
    onSubmit: handleSubmit,
  });

  return { loading, formik };
};

export default useChenckPhoneHook;
