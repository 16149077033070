import React, { useEffect, useState } from "react";
import PageBanner from "../../Components/PageBanner/PageBanner";
import { useTranslation } from "react-i18next";
import overlay from "./category_overlay.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { base_api } from "../../utils/apis";
import leftArrow from "./solar_arrow-left-outline.png";
import { base_url } from "../../utils/baseLink";

const AllCategorys = () => {
  const { t, i18n } = useTranslation();
  const [allcards, setAllCards] = useState([]);

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const response = await axios.get(
          `${base_api}/${i18n.language}/api/mobile/home/get-all-trips`
        );
        setAllCards(response.data.data);
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };

    fetchTrips();
  }, [i18n.language]);

  const {
    i18n: { language },
  } = useTranslation();
  return (
    <div>
      <PageBanner
        useTranslation={useTranslation}
        text={t("allCategorys.title")}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center my-10 w-4/5 m-auto">
        {allcards.map((item, index) => (
          <Link to={`/${base_url}/trips/${item.id}`}>
            <figure
              className="categories_card relative rounded-[30px] overflow-hidden"
              key={index}
            >
              <img className="w-full" src={item.photo} alt="" style={{height: "288.16px"}}  />
              <img
                className="absolute top-0 left-0 w-full h-full"
                src={overlay}
                alt=""
              />
              <div className="info absolute bottom-4 right-4 font-cairo">
                <h4 className="text-white font-semibold my-2">{item.name}</h4>
                {language === "en" ? (
                  <Link
                    to={item.link}
                    className="flex items-center justify-end gap-3 mb-2 font-medium text-sm text-white"
                  >
                    <img src={leftArrow} alt="" width={13.12} height={10.14} />
                    Show Trips
                  </Link>
                ) : (
                  <Link
                    to={item.link}
                    className="flex items-center justify-start gap-3 mb-2 font-medium text-sm text-white"
                  >
                    عرض الرحلات
                    <img src={leftArrow} alt="" width={13.12} height={10.14} />
                  </Link>
                )}
              </div>
            </figure>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AllCategorys;
