import React, { useEffect, useState } from "react";
import PageBanner from "../../Components/PageBanner/PageBanner";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import heart from "./assets/heart.png";
import mapIcon from "./assets/map_logo.png";
import arrow from "./assets/solar_round-arrow-left-up-bold.png";
import { base_url } from "../../utils/baseLink";
import axios from "axios";
import { base_api } from "../../utils/apis";
import { isLogedIn } from "../../utils/token";

export default function Favorites() {
  const { t, i18n } = useTranslation();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchTrips = async () => {
      const token = isLogedIn();
      if (!token) {
        console.error("No token found. User is not logged in.");
        return;
      }

      try {
        const response = await axios.get(
          `${base_api}/${i18n.language}/api/mobile/profile/get-favourite`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("fav", response.data.data.offers);
        setCards(response.data.data.offers || []);
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };

    const handleToggleFavorite = async (offer_id, index) => {
      try {
        const token = isLogedIn();
        if (!token) {
          console.error("No token found. User is not logged in.");
          return;
        }
        const response = await axios.post(
          `${base_api}/${i18n.language}/api/mobile/offers/toggle-favouriteOffer/${offer_id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data.data);
        
        const updatedCards = [...cards];
        updatedCards[index].is_favourite = response.data.data.is_favourite;
        setCards(updatedCards);
      } catch (error) {
        console.error("Error toggling favorite:", error);
      }
    };

    fetchTrips();
  }, [i18n.language]);

  return (
    <main>
      <PageBanner
        useTranslation={useTranslation}
        text={t("favoritePage.banner")}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center my-10 w-4/5 m-auto">
        {cards.length === 1 ? (
          <div
            className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1"
            key={cards[0].offer_id} // Use a unique key for single item
          >
            <figure className="h-[60%] relative">
              <img
                src={cards[0].photo}
                alt={cards[0].offer_name || "Image"}
                style={{ height: "100%", width: "100%" }}
              />
              <button className="absolute top-4 left-4 p-3 bg-white shadow-md rounded-full">
                <img src={heart} alt="Heart Icon" width={20} height={20} />
              </button>
            </figure>
            <div className="card_info px-3">
              <p className="flex items-center gap-2 py-3">
                <img src={mapIcon} alt="Map Icon" />
                <span className="text-[#777891]">
                  {cards[0].country ? `${cards[0].country} - ${cards[0].city || ''}` : "Location not available"}
                </span>
              </p>
              <h3 className="font-bold text-xl truncate py-3">
                {cards[0].offer_name || "Offer Name not available"}
              </h3>
            </div>
            <div className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]">
              <div className="right flex flex-col gap-2 w-[75%]">
                <p className="text-[#777891] text-sm">
                  {t("per_person", "Per person")}
                </p>
                <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                  {cards[0].offer_price_value || "N/A"}{" "}
                  <span className="mx-2 font-semibold text-sm py-1">$</span>
                </h2>
              </div>
              <div className="left w-[25%] h-full flex justify-end items-center">
                <Link to={`/${base_url}/tripInfo/${cards[0].tour_id}`} className="">
                  <img
                    className="bg-[#FD5674] rounded-full p-2 "
                    src={arrow}
                    alt="Arrow Icon"
                  />
                </Link>
              </div>
            </div>
          </div>
        ) : (
          cards.map((item) => (
            <div
              className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1"
              key={item.offer_id} // Use offer_id as the key
            >
              <figure className="h-[60%] relative">
                <img
                  src={item.photo}
                  alt={item.offer_name || "Image"}
                  style={{ height: "100%", width: "100%" }}
                />

              </figure>
              <div className="card_info px-3">
                <p className="flex items-center gap-2 py-3">
                  <img src={mapIcon} alt="Map Icon" />
                  <span className="text-[#777891]">
                    {item.country ? `${item.country} - ${item.city || ''}` : "Location not available"}
                  </span>
                </p>
                <h3 className="font-bold text-xl truncate py-3">
                  {item.offer_name || "Offer Name not available"}
                </h3>
              </div>
              <div className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]">
                <div className="right flex flex-col gap-2 w-[75%]">
                  <p className="text-[#777891] text-sm">
                    {t("per_person", "Per person")}
                  </p>
                  <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                    {item.offer_price_value || "N/A"}{" "}
                    <span className="mx-2 font-semibold text-sm py-1">$</span>
                  </h2>
                </div>
                <div className="left w-[25%] h-full flex justify-end items-center">
                  <Link to={`/${base_url}/tripInfo/${item.tour_id}`} className="">
                    <img
                      className="bg-[#FD5674] rounded-full p-2 "
                      src={arrow}
                      alt="Arrow Icon"
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </main>
  );
}
