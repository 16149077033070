import { CircularProgress } from '@mui/material'
import React from 'react'
import useInterNewPasswordHook from './Hooks/useInterNewPasswordHook'

const InterNewPassword = () => {
    const { loading, formik } = useInterNewPasswordHook();
  return (
    <div className="my-20 flex justify-center items-center">
      <form className="shadow-md w-full md:w-2/3 lg:w-1/3 py-4 px-4 md:px-10 mx-5 text-center flex flex-col" onSubmit={formik.handleSubmit}>
        <h1 className="mt-10 text-3xl mb-4">
          {/* {t("signUp.pgaeOne.title")}  */}
          {"ادخل كلمه المرور الجديده"}
        </h1>
          <input
            className="border w-full h-9 py-2 px-5"
            type="hidden"

            name={"code"}
            value={formik.values.code}
            onChange={formik.handleChange}
          />
        <div className="flex items-center mb-8">
          <input
            className="border w-full h-9 py-2 px-5"
            type="text"
            placeholder="أدخل كلمه المرور"
            name={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex items-center mb-8">
          <input
            className="border w-full h-9 py-2 px-5"
            type="text"
            placeholder="ادخل كلمه المرور مره اخري"
            name={"password_confirmation"}
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
          />
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {loading ? (
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          ) : (
            "ارسال رمز التحقق"
          )}
        </button>
      </form>
    </div>
  )
}

export default InterNewPassword